exports.components = {
  "component---src-pages-1-1-vyklyky-shcho-stoyat-na-shliahu-realizatsii-programy-index-tsx": () => import("./../../../src/pages/1-1-vyklyky-shcho-stoyat-na-shliahu-realizatsii-programy/index.tsx" /* webpackChunkName: "component---src-pages-1-1-vyklyky-shcho-stoyat-na-shliahu-realizatsii-programy-index-tsx" */),
  "component---src-pages-1-2-zviazok-programy-z-inshymy-stratehichnymy-dokumentamy-mista-index-tsx": () => import("./../../../src/pages/1-2-zviazok-programy-z-inshymy-stratehichnymy-dokumentamy-mista/index.tsx" /* webpackChunkName: "component---src-pages-1-2-zviazok-programy-z-inshymy-stratehichnymy-dokumentamy-mista-index-tsx" */),
  "component---src-pages-1-obgruntuvannia-potreby-stvorennia-programy-index-tsx": () => import("./../../../src/pages/1-obgruntuvannia-potreby-stvorennia-programy/index.tsx" /* webpackChunkName: "component---src-pages-1-obgruntuvannia-potreby-stvorennia-programy-index-tsx" */),
  "component---src-pages-10-finansove-zabezpechennia-vykonannia-programy-index-tsx": () => import("./../../../src/pages/10-finansove-zabezpechennia-vykonannia-programy/index.tsx" /* webpackChunkName: "component---src-pages-10-finansove-zabezpechennia-vykonannia-programy-index-tsx" */),
  "component---src-pages-11-faktory-ta-ryzyky-shcho-mozhut-vplyvaty-na-realizatsiiu-programy-index-tsx": () => import("./../../../src/pages/11-faktory-ta-ryzyky-shcho-mozhut-vplyvaty-na-realizatsiiu-programy/index.tsx" /* webpackChunkName: "component---src-pages-11-faktory-ta-ryzyky-shcho-mozhut-vplyvaty-na-realizatsiiu-programy-index-tsx" */),
  "component---src-pages-12-vykonannia-ta-monitorynh-realizatsii-programy-index-tsx": () => import("./../../../src/pages/12-vykonannia-ta-monitorynh-realizatsii-programy/index.tsx" /* webpackChunkName: "component---src-pages-12-vykonannia-ta-monitorynh-realizatsii-programy-index-tsx" */),
  "component---src-pages-13-ochikuvani-rezultaty-vid-realizatsii-zavdan-programy-u-2033-rotsi-index-tsx": () => import("./../../../src/pages/13-ochikuvani-rezultaty-vid-realizatsii-zavdan-programy-u-2033-rotsi/index.tsx" /* webpackChunkName: "component---src-pages-13-ochikuvani-rezultaty-vid-realizatsii-zavdan-programy-u-2033-rotsi-index-tsx" */),
  "component---src-pages-2-1-osnovni-rezultaty-doslidzhennia-index-tsx": () => import("./../../../src/pages/2-1-osnovni-rezultaty-doslidzhennia/index.tsx" /* webpackChunkName: "component---src-pages-2-1-osnovni-rezultaty-doslidzhennia-index-tsx" */),
  "component---src-pages-2-2-otsinka-umov-peresuvannia-na-velosypedi-index-tsx": () => import("./../../../src/pages/2-2-otsinka-umov-peresuvannia-na-velosypedi/index.tsx" /* webpackChunkName: "component---src-pages-2-2-otsinka-umov-peresuvannia-na-velosypedi-index-tsx" */),
  "component---src-pages-2-analityka-index-tsx": () => import("./../../../src/pages/2-analityka/index.tsx" /* webpackChunkName: "component---src-pages-2-analityka-index-tsx" */),
  "component---src-pages-3-meta-programy-index-tsx": () => import("./../../../src/pages/3-meta-programy/index.tsx" /* webpackChunkName: "component---src-pages-3-meta-programy-index-tsx" */),
  "component---src-pages-4-viziia-index-tsx": () => import("./../../../src/pages/4-viziia/index.tsx" /* webpackChunkName: "component---src-pages-4-viziia-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-tsili-programy-index-tsx": () => import("./../../../src/pages/5-tsili-programy/index.tsx" /* webpackChunkName: "component---src-pages-5-tsili-programy-index-tsx" */),
  "component---src-pages-6-pryntsypy-rozvytku-velomerezhi-ta-veloinfrastruktury-index-tsx": () => import("./../../../src/pages/6-pryntsypy-rozvytku-velomerezhi-ta-veloinfrastruktury/index.tsx" /* webpackChunkName: "component---src-pages-6-pryntsypy-rozvytku-velomerezhi-ta-veloinfrastruktury-index-tsx" */),
  "component---src-pages-7-1-velomerezha-lvivskoi-mth-index-tsx": () => import("./../../../src/pages/7-1-velomerezha-lvivskoi-mth/index.tsx" /* webpackChunkName: "component---src-pages-7-1-velomerezha-lvivskoi-mth-index-tsx" */),
  "component---src-pages-7-2-velosypedna-infrastruktura-index-tsx": () => import("./../../../src/pages/7-2-velosypedna-infrastruktura/index.tsx" /* webpackChunkName: "component---src-pages-7-2-velosypedna-infrastruktura-index-tsx" */),
  "component---src-pages-7-3-tymchasovi-zakhody-z-rozvytku-velomerezhi-index-tsx": () => import("./../../../src/pages/7-3-tymchasovi-zakhody-z-rozvytku-velomerezhi/index.tsx" /* webpackChunkName: "component---src-pages-7-3-tymchasovi-zakhody-z-rozvytku-velomerezhi-index-tsx" */),
  "component---src-pages-7-velomerezha-ta-velosypedna-infrastruktura-lvivskoi-mth-index-tsx": () => import("./../../../src/pages/7-velomerezha-ta-velosypedna-infrastruktura-lvivskoi-mth/index.tsx" /* webpackChunkName: "component---src-pages-7-velomerezha-ta-velosypedna-infrastruktura-lvivskoi-mth-index-tsx" */),
  "component---src-pages-8-1-stratehichni-ta-rehuliatorni-zavdannia-index-tsx": () => import("./../../../src/pages/8-1-stratehichni-ta-rehuliatorni-zavdannia/index.tsx" /* webpackChunkName: "component---src-pages-8-1-stratehichni-ta-rehuliatorni-zavdannia-index-tsx" */),
  "component---src-pages-8-2-instytutsiini-zavdannia-index-tsx": () => import("./../../../src/pages/8-2-instytutsiini-zavdannia/index.tsx" /* webpackChunkName: "component---src-pages-8-2-instytutsiini-zavdannia-index-tsx" */),
  "component---src-pages-8-3-infrastrukturni-zavdannia-index-tsx": () => import("./../../../src/pages/8-3-infrastrukturni-zavdannia/index.tsx" /* webpackChunkName: "component---src-pages-8-3-infrastrukturni-zavdannia-index-tsx" */),
  "component---src-pages-8-4-inshi-zavdannia-index-tsx": () => import("./../../../src/pages/8-4-inshi-zavdannia/index.tsx" /* webpackChunkName: "component---src-pages-8-4-inshi-zavdannia-index-tsx" */),
  "component---src-pages-8-zavdannia-programy-index-tsx": () => import("./../../../src/pages/8-zavdannia-programy/index.tsx" /* webpackChunkName: "component---src-pages-8-zavdannia-programy-index-tsx" */),
  "component---src-pages-9-vprovadzhennia-programy-index-tsx": () => import("./../../../src/pages/9-vprovadzhennia-programy/index.tsx" /* webpackChunkName: "component---src-pages-9-vprovadzhennia-programy-index-tsx" */),
  "component---src-pages-dodatky-1-perspektyvna-velosypedna-merezha-lvivskoi-mth-typy-marshrutiv-index-tsx": () => import("./../../../src/pages/dodatky/1-perspektyvna-velosypedna-merezha-lvivskoi-mth-typy-marshrutiv/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-1-perspektyvna-velosypedna-merezha-lvivskoi-mth-typy-marshrutiv-index-tsx" */),
  "component---src-pages-dodatky-2-1-ziednannia-naiavnoi-velosypednoi-merezhi-index-tsx": () => import("./../../../src/pages/dodatky/2-1-ziednannia-naiavnoi-velosypednoi-merezhi/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-2-1-ziednannia-naiavnoi-velosypednoi-merezhi-index-tsx" */),
  "component---src-pages-dodatky-2-2-budivnytstvo-ta-oblashtuvannia-novykh-kliuchovykh-dilianok-velosypednoi-merezhi-index-tsx": () => import("./../../../src/pages/dodatky/2-2-budivnytstvo-ta-oblashtuvannia-novykh-kliuchovykh-dilianok-velosypednoi-merezhi/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-2-2-budivnytstvo-ta-oblashtuvannia-novykh-kliuchovykh-dilianok-velosypednoi-merezhi-index-tsx" */),
  "component---src-pages-dodatky-2-3-budivnytstvo-novoi-velosypednoi-merezhi-index-tsx": () => import("./../../../src/pages/dodatky/2-3-budivnytstvo-novoi-velosypednoi-merezhi/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-2-3-budivnytstvo-novoi-velosypednoi-merezhi-index-tsx" */),
  "component---src-pages-dodatky-2-4-budivnytstvo-inshykh-dilianok-velosypednoi-merezhi-index-tsx": () => import("./../../../src/pages/dodatky/2-4-budivnytstvo-inshykh-dilianok-velosypednoi-merezhi/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-2-4-budivnytstvo-inshykh-dilianok-velosypednoi-merezhi-index-tsx" */),
  "component---src-pages-dodatky-2-plan-dii-z-budivnytstva-ta-oblashtuvannia-velosypednoi-merezhi-lvivskoi-mth-index-tsx": () => import("./../../../src/pages/dodatky/2-plan-dii-z-budivnytstva-ta-oblashtuvannia-velosypednoi-merezhi-lvivskoi-mth/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-2-plan-dii-z-budivnytstva-ta-oblashtuvannia-velosypednoi-merezhi-lvivskoi-mth-index-tsx" */),
  "component---src-pages-dodatky-3-perspektyvna-velosypedna-merezha-lvivskoi-mth-priorytetnist-index-tsx": () => import("./../../../src/pages/dodatky/3-perspektyvna-velosypedna-merezha-lvivskoi-mth-priorytetnist/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-3-perspektyvna-velosypedna-merezha-lvivskoi-mth-priorytetnist-index-tsx" */),
  "component---src-pages-dodatky-4-naiavna-velosypedna-merezha-lvivskoi-mth-index-tsx": () => import("./../../../src/pages/dodatky/4-naiavna-velosypedna-merezha-lvivskoi-mth/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-4-naiavna-velosypedna-merezha-lvivskoi-mth-index-tsx" */),
  "component---src-pages-dodatky-index-tsx": () => import("./../../../src/pages/dodatky/index.tsx" /* webpackChunkName: "component---src-pages-dodatky-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-passport-index-tsx": () => import("./../../../src/pages/passport/index.tsx" /* webpackChunkName: "component---src-pages-passport-index-tsx" */),
  "component---src-pages-slovnyk-index-tsx": () => import("./../../../src/pages/slovnyk/index.tsx" /* webpackChunkName: "component---src-pages-slovnyk-index-tsx" */),
  "component---src-pages-vstup-index-tsx": () => import("./../../../src/pages/vstup/index.tsx" /* webpackChunkName: "component---src-pages-vstup-index-tsx" */),
  "component---src-pages-zmist-index-tsx": () => import("./../../../src/pages/zmist/index.tsx" /* webpackChunkName: "component---src-pages-zmist-index-tsx" */)
}

